import TTSIcon from "@mui/icons-material/RecordVoiceOver";

// import VisitorList from "./VisitorList";
import TTSCreate from "./TTSCreate";
// import VisitorEdit from "./VisitorEdit";

const resource = {
  //   list: VisitorList,
  create: TTSCreate,
  //   edit: VisitorEdit,
  icon: TTSIcon,
};

export default resource;
