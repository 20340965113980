import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import LabelIcon from "@mui/icons-material/Label";

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";

import tts from "../tts";
import vidScriptToVoice from "../videoScriptToVoice";
import FetchVideo from "../fetchVideo";
// import visitors from "../visitors";
// import orders from "../orders";
// import invoices from "../invoices";
// import products from "../products";
// import categories from "../categories";
// import reviews from "../reviews";
// import SubMenu from "./SubMenu";

type MenuName = "menuCatalog" | "menuSales" | "menuCustomers";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <MenuItemLink
        to="/tts"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.tts.name`, {
          smart_count: 2,
        })}
        leftIcon={<tts.icon />}
        dense={dense}
      />
      {/* <MenuItemLink
        to="/fetch-video"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.fetch_video.name`, {
          smart_count: 2,
        })}
        leftIcon={<FetchVideo.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/video-script"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.video_script.name`, {
          smart_count: 2,
        })}
        leftIcon={<vidScriptToVoice.icon />}
        dense={dense}
      /> */}
      {/* <SubMenu
          handleToggle={() => handleToggle("menuTTS")}
          isOpen={state.menuTTS}
          name="pos.menu.tts"
          icon={<tts.icon />}
          dense={dense}
        >
          <MenuItemLink
            to="/tts"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.tts.name`, {
              smart_count: 2,
            })}
            leftIcon={<tts.icon />}
            dense={dense}
          />
        </SubMenu> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        name="pos.menu.sales"
        icon={<orders.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/commands"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.commands.name`, {
            smart_count: 2,
          })}
          leftIcon={<orders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/invoices"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.invoices.name`, {
            smart_count: 2,
          })}
          leftIcon={<invoices.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCatalog")}
        isOpen={state.menuCatalog}
        name="pos.menu.catalog"
        icon={<products.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/products"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.products.name`, {
            smart_count: 2,
          })}
          leftIcon={<products.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/categories"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.categories.name`, {
            smart_count: 2,
          })}
          leftIcon={<categories.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        name="pos.menu.customers"
        icon={<visitors.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/customers"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.customers.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/segments"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.segments.name`, {
            smart_count: 2,
          })}
          leftIcon={<LabelIcon />}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/reviews"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.reviews.name`, {
          smart_count: 2,
        })}
        leftIcon={<reviews.icon />}
        dense={dense}
      /> */}
    </Box>
  );
};

export default Menu;
