import {
  //   Create,
  //   DateInput,
  SimpleForm,
  TextInput,
  SelectInput,
  useTranslate,
  //   PasswordInput,
  //   NumberInput,
  //   email,
  useNotify,
  SaveButton,
  Toolbar,
} from "react-admin";
import { Box, Typography, Card, Stack, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { saveAs } from "file-saver";

import voiceList from "../../core/config/voiceList/google/v1beta1.json";
import style from "./index.module.css";

const DEFAULT_INPUT_VALUE = 160;

const VideoScriptToVoice = () => {
  const languages = [
    {
      id: "vi-VN",
      name: "Vietnamese(越南语)",
    },
    {
      id: "fil-PH",
      name: "Philippines(菲律宾语)",
    },
    {
      id: "en-US",
      name: "English(英语)",
    },
  ];
  const [audioBase64, setAudioBase64] = useState<string | null>(null);
  const [voices, setVoices] = useState<any[]>([]);
  const notify = useNotify();
  const [productNameWidth, setProductNameWidth] = useState(DEFAULT_INPUT_VALUE);
  const [productKeyWordsWidth, setProductKeyWordsWidth] =
    useState(DEFAULT_INPUT_VALUE);
  const [videoScript, setVideoScript] = useState<string | null>(null);

  function loadGapi() {
    const onGapiLoad = () => {
      gapi.load("client", async () => {
        await gapi.client.init({
          apiKey: "AIzaSyDxI0AyLWKBymGrtYahLXa50z0hzXeNepY",
        });
      });
    };
    if (!window?.gapi) {
      (window as any).onGapiLoad = onGapiLoad;
    } else {
      onGapiLoad();
    }
  }
  useEffect(loadGapi, []);

  const fetchVoiceList = (evt: any) => {
    // const res = await gapi?.client?.request({
    //   path: "https://texttospeech.googleapis.com/v1beta1/voices",
    //   params: {
    //     languageCode: evt.target?.value,
    //   },
    // });

    setVoices(
      voiceList?.filter((item) => item?.languageCodes[0] === evt.target?.value)
    );
  };

  const overChat = async (data: {
    lang: string;
    mins: string;
    productName: string;
    productKeyWords: string;
    prompt: string;
  }) => {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket("wss://overchat.fly.dev");

      // Listen for messages
      socket.addEventListener("message", function (event) {
        resolve(JSON.parse(event?.data ?? ""));
        socket.close(1000);
      });

      socket.addEventListener("error", function (event) {
        console.log("WebSocket error: ", event);
        reject(event);
      });

      socket.addEventListener("open", function () {
        socket.send(
          `用${data?.lang}写一份${data?.mins}分钟的商品销售视频的台词脚本，商品名称：${data?.productName}，商品的其他资料：${data?.productKeyWords}`
        );
      });
    });
  };

  const onSubmit = async (data: any) => {
    const answer = await overChat(data);
    setVideoScript((answer as { text: string })?.text);
    const res = await gapi?.client?.request({
      path: "https://texttospeech.googleapis.com/v1beta1/text:synthesize",
      method: "POST",
      body: {
        input: {
          text: (answer as { text: string })?.text,
        },
        voice: {
          languageCode: data?.lang,
          name: data?.name,
          ssmlGender: voices?.find((item) => item.name === data?.name)
            ?.ssmlGender,
        },
        audioConfig: {
          audioEncoding: "MP3",
          speakingRate: data?.speakingRate,
          effectsProfileId: ["large-home-entertainment-class-device"],
        },
      },
    });
    if (!res?.result?.audioContent) return;
    setAudioBase64(`data:audio/mpeg;base64,${res?.result?.audioContent}`);
    saveAs(
      `data:audio/mpeg;base64,${res?.result?.audioContent}`,
      `${data?.text?.substring(0, 10)}....mp3`
    );
    notify(`Voice Generated and Download Success`, {
      type: "success",
      undoable: true,
    });
  };

  const TtsToolbar = () => {
    return (
      <Toolbar>
        <SaveButton
          id="post_voice_form"
          label="Generate Video Script and Download Audio"
        />

        {audioBase64 && <audio src={audioBase64} controls />}
      </Toolbar>
    );
  };

  return (
    <Card
      sx={{
        marginTop: "1em",
      }}
    >
      <SimpleForm
        id="post_voice_form"
        sx={{ maxWidth: 800 }}
        toolbar={<TtsToolbar />}
        onSubmit={onSubmit}
        // Here for the GQL provider
        defaultValues={{
          // text: "",
          lang: "",
          name: "",
          mins: null,
          productName: "",
          productKeyWords: "",
          speakingRate: 1,
        }}
        // validate={validateForm}
      >
        <SectionTitle label="resources.video_script.fieldGroups.text" />
        <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
          <Typography>用</Typography>
          <SelectInput
            SelectProps={{ autoWidth: true }}
            hiddenLabel
            margin="none"
            variant="standard"
            required
            label="language"
            source="lang"
            choices={languages}
            emptyText="No Language Selected"
            onChange={fetchVoiceList}
            optionValue="id"
            sx={{
              minWidth: "100px",
            }}
          />
          <Typography>写一份</Typography>
          <TextInput
            hiddenLabel
            margin="none"
            variant="standard"
            size="small"
            type="number"
            label="mins"
            source="mins"
            sx={{
              width: "80px",
            }}
          />
          <Typography>分钟的商品销售视频的台词脚本，商品名称：</Typography>
          <TextInput
            margin="none"
            variant="standard"
            size="small"
            source="productName"
            label="product name"
            required
            onChange={(e) =>
              setProductNameWidth(
                e?.target?.value?.length * 20 < DEFAULT_INPUT_VALUE
                  ? DEFAULT_INPUT_VALUE
                  : e?.target?.value?.length * 20
              )
            }
            // fullWidth
            // multiline
            maxRows={1}
            resettable
            sx={{
              width: `${productNameWidth}px`,
            }}
          />
          <Typography>，商品的其他资料：</Typography>
          <TextInput
            margin="none"
            variant="standard"
            source="productKeyWords"
            label="product key words"
            required
            // fullWidth
            multiline
            resettable
            onChange={(e) =>
              setProductKeyWordsWidth(
                e?.target?.value?.length * 20 < DEFAULT_INPUT_VALUE
                  ? DEFAULT_INPUT_VALUE
                  : e?.target?.value?.length * 20
              )
            }
            sx={{
              width: `${productKeyWordsWidth}px`,
            }}
          />
        </Stack>
        {videoScript && <Chip label={videoScript} />}
        <Separator />
        <SectionTitle label="resources.video_script.fieldGroups.voice" />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <SelectInput
              margin="none"
              variant="standard"
              SelectProps={{ autoWidth: true }}
              required
              fullWidth
              label="voice"
              source="name"
              choices={voices}
              optionValue="name"
              optionText={(choice) => `${choice?.name} - ${choice?.ssmlGender}`}
              emptyText="No Voice Selected"
              sx={{ width: "100px" }}
              //   onChange={fetchVoiceList}
            />
            <TextInput
              variant="standard"
              size="small"
              resettable
              type="number"
              label="Speaking Rate (0.25 ~ 4)"
              source="speakingRate"
              fullWidth
              parse={(value) => {
                if (value === "") return value;
                if (value > 4) return 4;
                if (value < 0.25) return 0.25;
                return value;
              }}
            />
          </Stack>
        </Box>
      </SimpleForm>
    </Card>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default VideoScriptToVoice;
