import {
  //   Create,
  //   DateInput,
  SimpleForm,
  TextInput,
  SelectInput,
  useTranslate,
  //   PasswordInput,
  //   NumberInput,
  //   email,
  useNotify,
  SaveButton,
  Toolbar,
} from "react-admin";
import { Box, Typography, Card } from "@mui/material";
import { useState, useEffect } from "react";
import { saveAs } from "file-saver";

import voiceList from "../../core/config/voiceList/google/v1beta1.json";

const ttsCreate = () => {
  const languages = [
    {
      id: "vi-VN",
      name: "Vietnamese(越南语)",
    },
    {
      id: "fil-PH",
      name: "Philippines(菲律宾语)",
    },
  ];
  const [audioBase64, setAudioBase64] = useState<string | null>(null);
  //   const [gapiInitted, setGapiInitted] = useState(false);
  const [voices, setVoices] = useState<any[]>([]);
  const notify = useNotify();
  function loadGapi() {
    const onGapiLoad = () => {
      gapi.load("client", async () => {
        await gapi.client.init({
          apiKey: "AIzaSyDxI0AyLWKBymGrtYahLXa50z0hzXeNepY",
        });
        // setGapiInitted(true);
      });
    };
    if (!window?.gapi) {
      (window as any).onGapiLoad = onGapiLoad;
    } else {
      onGapiLoad();
    }
  }
  useEffect(loadGapi, []);

  const fetchVoiceList = (evt: any) => {
    // const res = await gapi?.client?.request({
    //   path: "https://texttospeech.googleapis.com/v1beta1/voices",
    //   params: {
    //     languageCode: evt.target?.value,
    //   },
    // });

    setVoices(
      voiceList?.filter((item) => item?.languageCodes[0] === evt.target?.value)
    );
  };

  const overChat = async ({
    lang,
    prompt,
  }: {
    lang: string;
    prompt: string;
  }) => {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket("wss://overchat.fly.dev");

      // Connection opened
      // socket.addEventListener("open", function (event) {
      //   socket.send("Hello Server!");
      // });

      // Listen for messages
      socket.addEventListener("message", function (event) {
        resolve(JSON.parse(event?.data ?? ""));
        socket.close(1000);
      });

      socket.addEventListener("error", function (event) {
        console.log("WebSocket error: ", event);
        reject(event);
      });

      socket.addEventListener("open", function () {
        socket.send(`请用${lang}翻译下面这段话:
        ${prompt}`);
      });
    });
  };

  const onSubmit = async (data: any) => {
    // const answer = await overChat({
    //   lang: languages?.find((item) => item.id === data?.lang)?.name as string,
    //   prompt: data?.text,
    // });
    const res = await gapi?.client?.request({
      path: "https://texttospeech.googleapis.com/v1beta1/text:synthesize",
      method: "POST",
      body: {
        input: {
          text: data?.text,
        },
        voice: {
          languageCode: data?.lang,
          name: data?.name,
          ssmlGender: voices?.find((item) => item.name === data?.name)
            ?.ssmlGender,
        },
        audioConfig: {
          audioEncoding: "MP3",
          speakingRate: data?.speakingRate,
          effectsProfileId: ["large-home-entertainment-class-device"],
        },
      },
    });
    if (!res?.result?.audioContent) return;
    setAudioBase64(`data:audio/mpeg;base64,${res?.result?.audioContent}`);
    saveAs(
      `data:audio/mpeg;base64,${res?.result?.audioContent}`,
      `${data?.text?.substring(0, 10)}....mp3`
    );
    notify(`Voice Generated and Download Success`, {
      type: "success",
      undoable: true,
    });
  };

  const TtsToolbar = () => {
    return (
      <Toolbar>
        <SaveButton id="post_voice_form" label="Download Voice" />

        {audioBase64 && <audio src={audioBase64} controls />}
      </Toolbar>
    );
  };

  return (
    <Card
      sx={{
        marginTop: "1em",
      }}
    >
      <SimpleForm
        id="post_voice_form"
        sx={{ maxWidth: 800 }}
        toolbar={<TtsToolbar />}
        onSubmit={onSubmit}
        // Here for the GQL provider
        defaultValues={{
          text: "",
          lang: "",
          name: "",
          speakingRate: 1,
        }}
        // validate={validateForm}
      >
        <SectionTitle label="resources.tts.fieldGroups.text" />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <TextInput
            source="text"
            label="text to speak"
            required
            fullWidth
            multiline
            resettable
          />
          {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="text_to_speak"
            isRequired
            fullWidth
            multiline
            resettable
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="last_name" isRequired fullWidth />
        </Box> */}
        </Box>
        {/* <TextInput type="email" source="email" isRequired fullWidth />
      <DateInput source="birthday" /> */}
        <Separator />
        <SectionTitle label="resources.tts.fieldGroups.voice" />
        {/* <TextInput source="address" multiline fullWidth helperText={false} /> */}
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              required
              fullWidth
              label="language/locale"
              source="lang"
              choices={languages}
              emptyText="No Language Selected"
              onChange={fetchVoiceList}
              optionValue="id"
            />
            {/* <TextInput source="language/locale" fullWidth helperText={false} /> */}
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              required
              fullWidth
              label="voice"
              source="name"
              choices={voices}
              optionValue="name"
              optionText={(choice) => `${choice?.name} - ${choice?.ssmlGender}`}
              emptyText="No Voice Selected"
              //   onChange={fetchVoiceList}
            />
            {/* <TextInput source="stateAbbr" fullWidth helperText={false} /> */}
          </Box>
          <Box flex={1}>
            <TextInput
              resettable
              type="number"
              label="Speaking Rate (0.25 ~ 4)"
              source="speakingRate"
              fullWidth
              parse={(value) => {
                if (value === "") return value;
                if (value > 4) return 4;
                if (value < 0.25) return 0.25;
                return value;
              }}
            />
            {/* <SelectInput
              source="Speaking Rate"
              choices={[
                {
                  id: 0.25,
                  name: "0.25",
                },
                {
                  id: 0.2,
                  name: "0.20",
                },
                {
                  id: 0.15,
                  name: "0.15",
                },
                {
                  id: 1,
                  name: "Normal",
                },
                {
                  id: 1.25,
                  name: "1.25",
                },
                {
                  id: 1.5,
                  name: "1.5",
                },
                {
                  id: 2.0,
                  name: "2.0",
                },
                {
                  id: 2.05,
                  name: "2.05",
                },
                {
                  id: 1.5,
                  name: "1.5",
                },
              ]}
              emptyText="No Language Selected"
              onChange={fetchVoiceList}
            /> */}
            {/* <TextInput source="Speaking Rate" fullWidth helperText={false} /> */}
          </Box>
        </Box>
        {/* <Separator />
      <SectionTitle label="resources.customers.fieldGroups.password" />
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <PasswordInput source="password" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <PasswordInput source="confirm_password" fullWidth />
        </Box>
      </Box> */}
      </SimpleForm>
    </Card>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default ttsCreate;
