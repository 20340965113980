import VisitorIcon from '@mui/icons-material/People';

import VisitorList from './VisitorList';
import VisitorCreate from './VisitorCreate';
import VisitorEdit from './VisitorEdit';

const resource = {
    list: VisitorList,
    create: VisitorCreate,
    edit: VisitorEdit,
    icon: VisitorIcon,
};

export default resource;
